/* Black border for #tableModule */
#tableModule .ql-editor table, 
#tableModule .ql-editor tr, 
#tableModule .ql-editor td, 
#tableModule .ql-editor th {
    border: 1px solid black !important;
    border-collapse: collapse;
    padding: 4px;
}
#tableModule  .ql-editor table:first-of-type {
    border: none !important;
    padding :2px !important

  }
  #tableModule  .ql-editor table:first-of-type td,
  #tableModule  .ql-editor table:first-of-type tr {
    
    border: none !important;
    padding :2px !important
  }