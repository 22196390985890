
#AddressModule .ql-editor table {
    width: 100%;
    font-family: 'Courier New', monospace;
    border-collapse: collapse;
    border: none !important; /* Removes table border */
    

  }
  
  #AddressModule .ql-editor tr, .ql-editor td {
    border:none !important;/* Removes cell borders */
    padding: 4px;
    
  }
  